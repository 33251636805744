<template>
  <section id="for-active-navbar" class="strategic-action-section">
    <TheVisionariesSection />
    <div class="strategic-action-section__header">
      <p>HOW WE TAKE YOU FROM DATA TO</p>
      <h2>
        STRATEGIC ACTION
      </h2>
    </div>
    <div class="strategic-action-section__body">
      <div class="strategic-action-section__body--image-section">
        <img
          src="@/assets/images/strategic-action/strategic-action-section-arrow-top.png"
          alt="strategic actions arrow top"
          class="arrow"
          height="auto"
          width="auto"
        />
        <div class="middle-image">
          <img
            src="@/assets/images/strategic-action/strategic-action-section-image.png"
            alt="strategic actions image"
            height="auto"
            width="100"
          />
        </div>
        <img
          src="@/assets/images/strategic-action/strategic-action-section-arrow-bottom.png"
          alt="arrow"
          class="arrow"
          height="auto"
          width="auto"
        />
      </div>
      <div class="strategic-action-section__body--steps-container">
        <img
          src="@/assets/images/strategic-action/strategic-action-section-arrow-top-mobile.png"
          alt="arrow"
          class="arrow arrow-left"
        />
        <a-row :gutter="[24]">
          <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
            <div class="step-card">
              <p class="step-card__sub-title">
                <img
                  src="@/assets/images/strategic-action/strategic-action-section-arrow-small-top.png"
                  alt="strategic actions image"
                />
                <span>Phase 1</span>
              </p>
              <h1 class="step-card__title">DATA COLLECTION</h1>
              <ul class="step-card__list">
                <li>Mainstream & Alternative News</li>
                <li>Scholarly Articles & Think Tank Publications</li>
                <li>Business & Government Publications</li>
                <li>Tailored access to your specific sources</li>
              </ul>
            </div>
          </a-col>
          <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
            <div class="step-card">
              <p class="step-card__sub-title">Phase 2</p>
              <h1 class="step-card__title">INTEGRATION</h1>
              <ul class="step-card__list">
                <li>
                  Context generated by cleaned, structured, & secured data
                </li>
              </ul>
            </div>
          </a-col>
          <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
            <div class="step-card">
              <p class="step-card__sub-title">Phase 3</p>
              <h1 class="step-card__title">ANALYSIS</h1>
              <ul class="step-card__list">
                <li>
                  Analysis is powered by AI & the collective intelligence of
                  professionals worldwide
                </li>
              </ul>
            </div>
          </a-col>
          <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
            <div class="step-card">
              <p class="step-card__sub-title">Phase 4</p>
              <h1 class="step-card__title">STRATEGIC ACTION</h1>
              <ul class="step-card__list step-card__last-list">
                <li>
                  Strategic & actionable insights for professionals & leaders in
                  the private & public sectors
                </li>
              </ul>
            </div>
          </a-col>
        </a-row>
        <img
          src="@/assets/images/strategic-action/strategic-action-section-arrow-bottom-mobile.png"
          alt=""
          class="arrow arrow-right"
        />
      </div>
      <img
        src="@/assets/images/strategic-action/strategic-action-section-bottom-arrow.png"
        alt="Arrow Down"
        class="strategic-action-section__body--bottom-arrow"
        width="auto"
        height="auto"
      />
    </div>
  </section>
</template>

<script>
import TheVisionariesSection from "./TheVisionariesSection.vue"
export default {
  components: {
    TheVisionariesSection,
  }
}
</script>

<style lang="scss">
.strategic-action-section {
  background-color: $color-black;
  padding: 8.9rem 13.2rem 13.9rem;
  @include respond(laptop-large) {
    padding: 8.9rem 11rem 13.9rem;
  }
  @include respond(tab-port) {
    padding: 5rem 4rem 10rem;
  }
  &__header {
    margin-bottom: 26rem;
    margin-top: 14.4rem;
    @include respond(laptop-large) {
      margin-bottom: 30rem;
    }
    @include respond(tab-port) {
      margin-bottom: 36rem;
    }
    p {
      font-family: $font-primary-medium;
      color: $color-primary;
      font-weight: 700;
      line-height: 3.1rem;
      font-size: 2.4rem;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 1.5rem;
      @include respond(phone-x-small) {
        font-size: 3rem;
        line-height: 4rem;
      }
    }
    h2 {
      font-family: $font-primary-bold;
      color: $color-white;
      font-weight: 700;
      line-height: 8.2rem;
      font-size: 6rem;
      text-transform: uppercase;
      max-width: 85%;
      text-align: center;
      margin: 0 auto;
      @include respond(laptop-small) {
        font-size: 4.4rem;
        line-height: 5rem;
        max-width: 100%;
      }
      @include respond(tab-port) {
        font-size: 4.4rem;
        line-height: 5rem;
        max-width: 100%;
      }
    }
  }
  &__body {
    position: relative;
    &--image-section {
      display: flex;
      align-items: center;
      width: auto;
      padding: 0 2rem;
      padding-right: 0;
      @include respond(tab-port) {
        padding: 0;
      }
      .middle-image {
        width: 100%;
        margin: 0 2rem;
        margin-top: -189px;
        height: auto;
        @include respond(tab-port) {
          margin: 0;
          margin-top: -34rem;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      img {
        width: auto;
        height: auto;
      }
      @include respond(tab-port) {
        .arrow {
          display: none;
        }
      }
    }
    &--steps-container {
      display: flex;
      position: relative;
      .arrow {
        position: absolute;
        top: 0;
        display: none;
        @include respond(tab-port) {
          display: block;
        }
      }
      .arrow-right {
        height: 94%;
        top: -9rem;
        right: 2rem;
        @media (min-width: 641px) and (max-width: 768px) {
          height: 97%;
        }
        @media (min-width: 346px) and (max-width: 366px) {
          height: 91%;
        }
        @media (min-width: 320px) and (max-width: 345px) {
          height: 91.5%;
        }
      }
      .arrow-left {
        height: 80%;
        @media (min-width: 641px) and (max-width: 768px) {
          height: 82%;
        }
        @media (min-width: 346px) and (max-width: 366px) {
          height: 78%;
        }
        @media (min-width: 320px) and (max-width: 345px) {
          height: 79.5%;
        }
      }
    }
    &--bottom-arrow {
      padding-left: 2rem;
      width: 99%;
      margin-top: -8rem;
      height: 100%;
      @include respond(tab-port) {
        display: none;
      }
    }
    .step-card {
      padding: 1.5rem 0;
      position: relative;
      height: 100%;
      @include respond(tab-port) {
        padding: 0 4rem;
      }
      &__sub-title {
        font-size: 1.8rem;
        font-family: $font-primary;
        margin-bottom: 1.6rem;
        color: #7b7b7b;
        margin-bottom: 1rem;
        @include respond(tab-port) {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: fit-content;
          position: relative;
          img {
            position: absolute;
            top: -7rem;
            display: block !important;
          }
        }
        img {
          display: none;
        }
      }
      &__title {
        font-size: 2.4rem;
        font-family: $font-primary-medium;
        margin-bottom: 1.6rem;
        color: $color-white;
      }
      &__list {
        padding-left: 3rem;
        li {
          font-size: 1.8rem;
          font-family: $font-primary-regular;
          margin-bottom: 1.6rem;
          color: $color-white;
          line-height: 2.2rem;
          max-width: 80%;
          list-style: disc;
        }
      }
      &__last-list {
        li {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
