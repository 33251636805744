<template>
  <div class="visionaries-section-container">
    <h2 class="visionaries-section-container__sub-title">Mission</h2>
    <h1 class="visionaries-section-container__title">To meaningfully improve the digital information environment</h1>
    <a-row :gutter="[60, 60]">
      <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div
          class="visionaries-section-container__card visionaries-section-container__card-one"
        >
          <!-- <h2
            class="visionaries-section-container__card--sub-title visionaries-section-container__card-one--sub-title"
          >
            Glocal is coming from the
          </h2> -->
          <h1
            class="visionaries-section-container__card--title visionaries-section-container__card-one--title"
          >
            Professionals
          </h1>
          <p
            class="visionaries-section-container__card--description visionaries-section-container__card-one--description"
          >
            <ul>
              <li><span>An Intelligent Digital Environment</span> – Glocal unites insights from credible subject matter experts, public sources, and AI tools to give you powerful perspectives for a competitive advantage on the topics most relevant to you.</li>
              <li><span>An Interactive and Inclusive News Environment</span> – join Glocal’s world-class expert network to elevate the digital information environment by: rating and evaluating news reports and publications, adding mainstream and alternative news sources/publications, holding public and/or private forums, and collaborating with other professionals and subject matter experts to drive positive change.</li>
              <li><span>Complete Independence</span> – your subscription allows us to serve you, not advertisers, and fuels our mission to elevate the news environment. We do not accept funding from advertisers as our only incentive is to serve our customers.</li>
            </ul>
          </p>
        </div>
      </a-col>
      <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <a-row :gutter="[60, 60]">
          <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div
              class="visionaries-section-container__card visionaries-section-container__card-two"
            >
              <!-- <h2
                class="visionaries-section-container__card--sub-title visionaries-section-container__card-two--sub-title"
              >
                NETWORK
              </h2> -->
              <h1
                class="visionaries-section-container__card--title visionaries-section-container__card-two--title"
              >
                Academic Institutions
              </h1>
              <p
                class="visionaries-section-container__card--description visionaries-section-container__card-two--description"
              >
              <ul>
                <li><span>Network</span> – deepen professional relationships developed in the classroom through supercharged collaboration.</li>
                <li><span>Knowledge</span> – enable students to transfer their academic braintrust to the workplace and stay updated as topics evolve.</li>
                <li><span>Support</span> – leverage AI to curate course materials, which will save faculty time, enrich topic insights, and deepen discussions.</li>
              </ul>
              </p>
            </div>
          </a-col>
          <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div
              class="visionaries-section-container__card visionaries-section-container__card-three"
            >
              <!-- <h2
                class="visionaries-section-container__card--sub-title visionaries-section-container__card-three--sub-title"
              >
                INTEGRATED WITH
              </h2> -->
              <h1
                class="visionaries-section-container__card--title visionaries-section-container__card-three--title"
              >
                Public & Private Institutions
              </h1>
              <p
                class="visionaries-section-container__card--description visionaries-section-container__card-three--description"
              >
              <ul>
                <li><span>Strategic Insights</span> – your time is valuable, which is why Glocal’s market leading software enables you to get answers fast: AI and ML-powered tools to collect data, analyze topics, visualize trends and patters, and summarize insights.</li>
                <li><span>Effective Collaboration</span> – execute goals more efficiently through collaboration with members in and outside of your organization: build custom research workspaces and discussion forums to stay current on the evolving global, local, and micro events and topics.</li>
              </ul>
              </p>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<style lang="scss">
.visionaries-section-container {
  padding: 0 2.2rem;
  &__sub-title {
    font-family: $font-primary-medium;
    color: $color-primary;
    font-weight: 700;
    line-height: 3.1rem;
    font-size: 2.4rem;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    @include respond(phone-x-small) {
      font-size: 3rem;
      line-height: 4rem;
    }
  }
  &__title {
    font-family: $font-primary-bold;
    color: $color-white;
    font-weight: 700;
    line-height: 8.2rem;
    font-size: 6rem;
    text-transform: uppercase;
    max-width: 85%;
    text-align: center;
    margin: 0 auto 10rem;
    @include respond(laptop-small) {
      font-size: 4.4rem;
      line-height: 5rem;
      max-width: 100%;
    }
    @include respond(tab-port) {
      font-size: 4.4rem;
      line-height: 5rem;
      max-width: 100%;
    }
  }
  .ant-row {
    @include respond(tab-port) {
      row-gap: 30px !important;
    }
  }
  &__card {
    padding: 6.6rem 5rem;
    border-radius: 2rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    box-shadow: rgba(255, 255, 255, 0.151) 0px 0px 38px 2px;
     @include respond(phone-x-small) {
      padding: 4rem 3rem;
    }
    &::before {
      content: '';
      background-color: rgba(0, 0, 0, 0.534);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 2rem;
    }
    &--sub-title {
      font-family: $font-primary;
      color: $color-white;
      font-weight: 400;
      line-height: 3.3rem;
      font-size: 2.4rem;
      margin-bottom: 0.2rem;
      position: relative;
      z-index: 1;
      @media (max-width: 991px) {
        font-size: 2.1rem !important;
        line-height: 2.5rem !important;
        margin-bottom: 2rem !important;
        text-transform: uppercase;
      }
    }
    &--title {
      font-family: $font-primary-bold;
      color: $color-white;
      font-weight: 700;
      font-size: 4rem;
      line-height: 5.5rem;
      margin-bottom: 2.2rem;
      position: relative;
      z-index: 1;
      @media (max-width: 991px) {
        font-size: 3.4rem !important;
        line-height: 5rem !important;
        margin-bottom: 1.8rem !important;
        text-transform: uppercase;
      }
    }
    &--description {
      font-family: $font-primary;
      color: $color-white;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin-bottom: 0;
      opacity: 1;
      position: relative;
      z-index: 1;
      max-width: 90%;
      ul {
        padding-left: 0;
        li {
          margin-bottom: 1.5rem;
          list-style: none;
          span {
            font-family: $font-primary-bold;
          }
        }
      }
      @media (max-width: 991px) {
        font-size: 2.5rem !important;
        line-height: 3rem !important;
        ul {
          padding-left: 2.5rem !important;
        }
      }
    }
  }
  &__card-one {
    height: 100%;
    background-image: url("../../assets/images/Professionals.jpg");
  }
  &__card-two {
    background-image: url("../../assets/images/Academic-Institutions.png");
    &--description {
      max-width: 80%;
    }
  }
  &__card-three {
    background-image: url("../../assets/images/Private-Public-Institutes.jpg");
    &--description {
      max-width: 80%;
    }
  }
}
</style>
