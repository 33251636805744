<template>
  <div class="landing-page-container">
    <hero-section></hero-section>
    <strategic-action-section></strategic-action-section>
    <!-- <about-us-section></about-us-section> -->
     <about-us-section-v2></about-us-section-v2>
    <expert-professional-section></expert-professional-section>
    <landing-page-footer></landing-page-footer>
  </div>
</template>

<script>
import HeroSection from "../components/LandingPageComponentsV2/HeroSection.vue";
import StrategicActionSection from "../components/LandingPageComponentsV2/StrategicActionSection.vue";
import ExpertProfessionalSection from "../components/LandingPageComponentsV2/ExpertProfessionalSection.vue";
import LandingPageFooter from "../components/layout/LandingPageFooter.vue";
// import AboutUsSection from "../components/LandingPageComponentsV2/AboutUsSection.vue";
import AboutUsSectionV2 from "../components/LandingPageComponentsV2/AboutUsSectionV2.vue"

export default {
  components: {
    HeroSection,
    StrategicActionSection,
    ExpertProfessionalSection,
    LandingPageFooter,
    // AboutUsSection
    AboutUsSectionV2
  },
};
</script>

<style lang="scss">
.landing-page-container {
  background-color: $color-black;
  overflow: hidden;
}
</style>
